<template>
  <div>
    <div class="text-grey1 mx-5 margin-top-50">
      Order Medicine > Order With Prescription > Pharmacy Listing
    </div>
    <b-card class="text-grey mx-5 my-4">
      <b-row>
        <b-col md="3">
          <!-- <h4 class="ml-3 mt-2">Filter By Distance</h4> -->
        </b-col>
        <b-col md="4">
          <!-- <b-form-input
            id="range-1"
            v-model="value"
            type="range"
            min="0"
            max="10"
            class="w-50 mt-2 margin-left-55"
          >
          </b-form-input> -->
        </b-col>
        <b-col md="2">
          <!-- <div class="mt-2 margin-left-235">Distance: {{ value }}km to {{ max }}km</div> -->
        </b-col>
        <b-col md="3">
          <b-row>
            <b-form-input
              class="mt-2 col-10 border-radius-left-40"
              placeholder="Search Store..."
              v-model="storeName"
            ></b-form-input>
            <button
              class="col search-button mt-2 ml-n1 border-radius-r-40"
              @click="storeResult"
            >
              <b-icon icon="search" class="margin-top-4"></b-icon>
            </button>
          </b-row>
        </b-col>
      </b-row>
    </b-card>
    <div>
      <sections-slider :list="pharmacyStore" :hasBackground="false" :withoutSlider="true">
        <template slot="default" slot-scope="{ listItem }">
          <div class="item-box">
            <items-item
              :itemWhite="true"
              :product="listItem"
              :rating="getProductFeedbackData(listItem._id).ratingAverage || 0"
              :marketRoute="{
                name: 'request-quote',
                params: {
                  storeData: listItem,
                  orderData2: $route.params.orderData,
                },
              }"
            >
            </items-item>
          </div>
        </template>
      </sections-slider>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
export default {
  name: "pharmacyStore",
  components: {},
  data() {
    return { value: "0", max: "10", storeName: "" };
  },
  computed: {
    ...mapState({
      pharmacyStore: (state) => state["healthCare"].pharmacyStore,
    }),
    ...mapGetters({
      getProductFeedbackData: "healthCare/getProductFeedbackData",
    }),
  },
  methods: {
    ...mapActions({
      fetchPharmacyStore: "healthCare/fetchPharmacyStore",
      fetchFeedbackData: "healthCare/fetchProductFeedback",
    }),

    async getPharmacyData() {
      const payload = {
        category: "SELLER",
        name: "",
        state: "",
        city: "",
        zip: "",
        street: "",
        market: "",
        lat: 17.385044,
        lng: 78.486671,
        radius: 5,
      };
      if (this.storeName) {
        payload.name = this.storeName;
      }
      await this.fetchPharmacyStore(payload);
    },

    async storeResult() {
      await this.getPharmacyData();
    },

    async getProductFeedback() {
      const payload = this.pharmacyStore.map((x) => {
        const catalogId = x.agreement.marketSegment[0].catalogId;
        return this.fetchFeedbackData({ _id: x._id, catalogId });
      });
      await Promise.all(payload);
    },
  },
  async mounted() {
    await this.getPharmacyData();
    await this.getProductFeedback();
  },
};
</script>

<style scoped>
.text-grey1 {
  color: #aaa;
  background-color: transparent;
}

.text-grey {
  background-color: #f9f9f9;
}

.search-button {
  position: absolute;
  top: 0px;
  font-size: large;
  z-index: 1;
  right: 40px;
  width: 46px;
  height: 37px;
  padding: 0px;
  background-color: black !important;
  color: #fff;
}
</style>
